import { RemoteCollectionStore } from '@softline/core';
import { Objekt } from './data/objekt.model';

export const SOFTLINE_FEATURE_TIME_REPORT_RECENT_OBJECT =
  'timeReportRecentObject';
export const SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA = 'timeReportCoreData';
export const SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES =
  'timeReportPartyFavorites';
export const SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT = 'timeReportTimeReport';

export const RecentObjectsStore = RemoteCollectionStore.create<Objekt>();
