import { Employee } from '../data/employee.model';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { CoreDataService } from '../services/core-data.service';
import {lastValueFrom} from 'rxjs';

export type CoreDataState = {
  employees: Employee[];
  vkfOrgs: any[];
  loading: boolean;
};

class Store {
  actions = {
    loadCoreData: createAction<
      CoreDataState,
      undefined,
      { employees: Employee[]; vkfOrgs: any[] }
    >('loadCoreData'),
  };

  mutations = {
    setLoading: createMutation<CoreDataState, boolean>('setLoading'),
    setData: createMutation<
      CoreDataState,
      { employees: Employee[]; vkfOrgs: any[] }
    >('setEmployees'),
  };

  getters = {
    selectPersonsWithFavoriteInfo: createGetter<CoreDataState, Employee[], any>(
      'selectPersonsWithFavoriteInfo'
    ),
    selectVkfOrgs: createGetter<CoreDataState, any[], any>('selectVkfOrgs'),
    selectLoading: createGetter<CoreDataState, boolean, any>('selectLoading'),
  };

  feature: StoreFeature<CoreDataState> = {
    initialState: {
      employees: [],
      vkfOrgs: [],
      loading: false,
    },
    mutations: [
      mutate(this.mutations.setLoading, ({ state, params }) => ({
        ...state,
        loading: params,
      })),
      mutate(this.mutations.setData, ({ state, params }) => ({
        ...state,
        ...params,
        loading: false,
      })),
    ],
    getters: [
      select(
        this.getters.selectPersonsWithFavoriteInfo,
        ({ state }) => state.employees
      ),
      select(this.getters.selectVkfOrgs, ({ state }) => state.vkfOrgs),
      select(this.getters.selectLoading, ({ state }) => state.loading),
    ],
    actions: [
      on(
        this.actions.loadCoreData,
        async ({ store, injector, featureName }) => {
          store.commit(featureName, this.mutations.setLoading, true);
          const service = injector.get(CoreDataService);
          const result = await lastValueFrom(service.getCoreData());
          const data = {
            employees: result.mitarbeiterInAbteilungModule,
            vkfOrgs: result.vkforgModule,
          };
          store.commit(featureName, this.mutations.setLoading, false);
          store.commit(featureName, this.mutations.setData, data);
          return data;
        }
      ),
    ],
  };
}

export const CoreDataStore = new Store();
