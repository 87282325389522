import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ApplicationModule,
  ArchiveFile,
  ArchiveStore,
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_ARCHIVE,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
  UploadFileCommand,
} from '@softline/application';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom, lastValueFrom,
  Observable,
  of,
  Subscription
} from "rxjs";
import {
  ConnectionResourceLocation, DateService,
  RequestError,
  Store,
} from '@softline/core';
import {
  ARCHIVE_KEY_RESOURCE_PATH,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST
} from '../../arbeitsbericht.api';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeReport } from '../../data/time-report.model';
import { SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT } from '../../arbeitsbericht.shared';
import { TimeReportStore } from '../../store/time-report.store';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import {
  DefinitionStore,
  DynamicModule,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { Objekt } from '../../data/objekt.model';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SOFTLINE_PERMISSION_TIME_REPORT_PARTY } from '../../arbeitsbericht.permissions';
import {
  SOFTLINE_CONFIG_ARBEITSBERICHT,
  ArbeitsberichtConfig,
} from '../../arbeitsbericht.config';
import { ConfirmDeleteDialogComponent } from '../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-edit',
  standalone: true,
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  imports: [
    CommonModule,
    UiCoreModule,
    DynamicModule,
    ReactiveFormsModule,
    ApplicationModule,
  ],
})
export class EditComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;
  private commandSubscription?: Subscription;

  private GOOGLE_LINK = 'https://www.google.com/maps/search/?api=1&query=';

  saving$ = new BehaviorSubject<boolean>(false);

  timeReport$: Observable<TimeReport> = combineLatest([
    this.activatedRoute.paramMap,
    this.store.observe(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.getters.entities
    ),
  ]).pipe(
    map(([params, entities]) => {
      const id = params.get('id') ?? -1;
      const entity = { ...entities[id] };
      // TODO: Time
      if (entity.from && !entity.from.startsWith('T')) {
        entity.from = 'T' + entity.from;
      }
      if (entity.to && !entity.to.startsWith('T')) {
        entity.to = 'T' + entity.to;
      }

      if (
        entity?.zbewegungsarten &&
        Array.isArray(entity?.zbewegungsarten) &&
        entity?.zbewegungsarten?.length > 0
      ) {
        entity.zbewegungsarten = entity.zbewegungsarten[0];
      }

      return entity as TimeReport;
    })
  );

  isAuthorized$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIME_REPORT_PARTY
  );

  inputDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT
  );
  listDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    @Inject(SOFTLINE_CONFIG_ARBEITSBERICHT)
    readonly timeReportConfig: ArbeitsberichtConfig,
    private dateService: DateService,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIME_REPORT.COMPONENTS.EDIT.TITLE'
    );
    this.subscription = this.activatedRoute.paramMap.subscribe(async (o) => {
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.actions.loadReportDetails,
        { id: o.get('id') }
      );
    });
    this.commandSubscription = this.timeReport$.subscribe((o) => {
      const commands = this.createCommands(o);
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        { name: EditComponent, commands }
      );
    });
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.commandSubscription && !this.commandSubscription.closed)
      this.commandSubscription.unsubscribe();
    this.commandSubscription = undefined;

    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      EditComponent
    );
  }

  async navigateBack(): Promise<void> {
    const timeReport = await firstValueFrom(this.timeReport$);
    await this.router.navigate(['/arbeitsbericht', { date: timeReport?.date ?? this.dateService.today() }]);
  }

  async onSubmit(value: Partial<TimeReport>): Promise<void> {
    let timeReport = await firstValueFrom(this.timeReport$);
    timeReport = { ...timeReport, ...value };

    // TODO: Time
    if (timeReport.from?.startsWith('T')) {
      timeReport.from = timeReport.from.substring(1, 9);
    }
    if (timeReport.to?.startsWith('T')) {
      timeReport.to = timeReport.to.substring(1, 9);
    }

    if (value?.zbewegungsarten) {
      const storeTimeReport = this.store.get(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.getters.entity,
        timeReport.id
      );

      if (
        storeTimeReport?.zbewegungsarten &&
        Array.isArray(storeTimeReport?.zbewegungsarten)
      ) {
        if (value?.zbewegungsarten && (value?.zbewegungsarten as any)?.id) {
          if (
            storeTimeReport.zbewegungsarten
              .map((o) => o.id)
              .includes((value?.zbewegungsarten as any)?.id)
          ) {
            timeReport.zbewegungsarten = [
              ...(storeTimeReport?.zbewegungsarten ?? []),
            ] as any[];
          } else {
            timeReport.zbewegungsarten = [
              ...(storeTimeReport?.zbewegungsarten ?? []),
              value?.zbewegungsarten,
            ] as any[];
          }
        } else if (Array.isArray(value?.zbewegungsarten)) {
          const result: any[] = [];
          for (const art of storeTimeReport?.zbewegungsarten) {
            if (value?.zbewegungsarten?.map((o) => o.id).includes(art.id)) {
              return;
            }
            result.push(art);
          }
          timeReport.zbewegungsarten = result;
        } else {
          timeReport.zbewegungsarten = [
            ...((storeTimeReport?.zbewegungsarten as any) ?? []),
            value?.zbewegungsarten,
          ];
        }
      }
    }

    try {
      this.saving$.next(true);
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.actions.update,
        { entity: timeReport }
      );
      this.saving$.next(false);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#TIME_REPORT.MESSAGES.SUCCESS_UPDATE.TITLE',
          message: '#TIME_REPORT.MESSAGES.SUCCESS_UPDATE.SAVE',
        }
      );
    } catch (e) {
      this.saving$.next(false);
      handleRequestErrors(this.store, e);
    }
    finally {
      this.saving$.next(false);
    }
  }

  protected createCommands(selected: TimeReport): Command[] {
    const imageUpload = new UploadFileCommand(this.store, {
      sources: 'all',
      canExecute: of(true),
      archiveKey:
        selected?.archiveKey ?? this.getConnectionResourceLocation(selected),
      onSuccess: async (files) => {
        console.log('upload success');
        await this.updateArchiveKey(
          selected,
          (files[0] as unknown as { result: ArchiveFile })?.result
        );
        const id = this.activatedRoute?.snapshot?.params['id'] ?? selected?.id;
        await this.store.dispatch(
          SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
          TimeReportStore.actions.loadReportDetails,
          { id }
        );
      },
    });

    if (this.timeReportConfig && this.timeReportConfig?.allowDelete) {
      return [
        imageUpload,
        {
          name: 'Eintrag löschen',
          class: 'menu action-menu action-menu-top',
          icon: 'fas fa-trash',
          execute: async () => {
            const result = await this.store.dispatch(
              SOFTLINE_FEATURE_MODAL,
              ModalStore.actions.open(),
              {
                component: ConfirmDeleteDialogComponent,
                dismiss: { backdrop: true, button: true },
                id: 'TIME_REPORT_CONFIRM_DELETE',
              }
            );

            if (result && result !== 'DISMISSED') {
              try {
                const timeReport = await firstValueFrom(this.timeReport$);

                await this.store.dispatch(
                  SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
                  TimeReportStore.actions.delete,
                  { entity: selected, pathParams: { id: selected.id } }
                );

                await this.store.dispatch(
                  SOFTLINE_FEATURE_MESSAGE_BAR,
                  MessageBarStore.actions.success,
                  { title: 'Eintrag wurde gelöscht!' }
                );

                await this.router.navigate(['/arbeitsbericht', { date: timeReport?.date ?? this.dateService.today() }]);
              } catch (e) {
                handleRequestErrors(this.store, e);
              }
            }
          },
        },
      ];
    }

    return [imageUpload];
  }

  private async showUploadSuccess() {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: 'Upload erfolgreich',
        message: 'Das Bild wurde erfolgreich hochgeladen',
      }
    );
  }

  private async showUploadFailure() {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: 'Upload fehlgeschlagen',
        message: 'Das Bild konnte nicht hochgeladen werden',
      }
    );
  }

  private async updateArchiveKey(
    entity: TimeReport,
    { archiveKey }: ArchiveFile
  ): Promise<void> {
    if (!archiveKey) {
      return;
    }
    await this.store.dispatch(
      SOFTLINE_FEATURE_ARCHIVE,
      ArchiveStore.actions.read,
      { key: archiveKey }
    );
    this.store.commit(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.mutations.updateArchiveKey,
      { entity, archiveKey }
    );
  }

  private getConnectionResourceLocation(selected: any): ConnectionResourceLocation {
    return {
      path: ARCHIVE_KEY_RESOURCE_PATH,
      pathParams: { id: selected?.id },
    };
  }

  getGoogleLink(objekt: any): string | undefined {
    let link = this.GOOGLE_LINK;
    if (objekt.geodaten !== null) {
      link += objekt?.geodaten?.gpsbreite + ',' + objekt?.geodaten?.gpslaenge;
      return encodeURI(link);
    }
    return undefined;
  }
}
