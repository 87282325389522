import {
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  ScannerStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
  UploadFileCommand,
} from '@softline/application';
import {BehaviorSubject, of, retry, Subject, Subscription} from 'rxjs';
import { TimeReport } from '../../data/time-report.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConnectionResourceLocation,
  DateService,
  RequestError,
  Store,
} from '@softline/core';
import { ARCHIVE_KEY_RESOURCE_PATH } from '../../arbeitsbericht.api';
import { PartyDialogComponent } from '../dialogs/party-dialog/party-dialog.component';
import { Objekt } from '../../data/objekt.model';
import { RecentObjectsDialogComponent } from '../dialogs/recent-objects-dialog/recent-objects-dialog.component';
import { Employee } from '../../data/employee.model';
import {
  SOFTLINE_CONFIG_ARBEITSBERICHT,
  ArbeitsberichtConfig,
} from '../../arbeitsbericht.config';
import { SOFTLINE_PERMISSION_TIME_REPORT_PARTY } from '../../arbeitsbericht.permissions';
import {
  AuthenticationContextStore,
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import {
  SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
  SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
} from '../../arbeitsbericht.shared';
import { TimeReportStore } from '../../store/time-report.store';
import { PartyFavoritesStore } from '../../store/party-favorites.store';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import { TimeReportComponent } from '../time-report.component';
import { InputComponent } from './input/input.component';
import { ListComponent } from './list/list.component';
import moment from 'moment';

interface UserInfo {
  userId: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  imports: [CommonModule, UiCoreModule, ListComponent, InputComponent],
})
export class OverviewComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;
  private commandSubscription?: Subscription;
  isInputExpanded = false;
  selectedPersons: Employee[] = [];

  readonly hasPartyPermission$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIME_REPORT_PARTY
  );
  readonly selectedObject$ = new Subject<Objekt | null>();

  readonly canCreate$ = new BehaviorSubject<boolean>(true);

  date = this.dateService.today();
  selected$ = new BehaviorSubject<TimeReport | undefined>(undefined);

  constructor(
    @Optional()
    @Inject(SOFTLINE_CONFIG_ARBEITSBERICHT)
    readonly timeReportConfig: ArbeitsberichtConfig,
    private store: Store,
    protected router: Router,
    private activeRoute: ActivatedRoute,
    private zone: NgZone,
    private dateService: DateService,
    private backNavigationService: BackNavigationService
  ) {}

  private static getConnectionResourceLocation(
    selected: any
  ): ConnectionResourceLocation {
    return {
      path: ARCHIVE_KEY_RESOURCE_PATH,
      pathParams: { id: selected?.id },
    };
  }

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIME_REPORT.TITLE'
    );
    this.backNavigationService.set(this);

    const user: UserInfo = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    ) as UserInfo;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
        PartyFavoritesStore.actions.loadMany,
        { clear: true }
      );
    } catch (e) {
      handleRequestErrors(this.store, e)
    }

    this.subscription = this.activeRoute.paramMap.subscribe(async (params) => {
      this.date = params.has('date')
        ? (params.get('date') ?? this.dateService.today())
        : this.dateService.today();

      this.date = this.date.substring(0, 10);

      if (!this.date || !user?.userId) return;

      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
          TimeReportStore.actions.loadReports,
          {
            from: this.date ?? this.dateService.today(),
            to: this.date ?? this.dateService.today(),
            userId: user?.userId + '',
          }
        );
      } catch (e) {
        handleRequestErrors(this.store, e);
      }
    });

    this.commandSubscription = this.selected$.subscribe((o) => {
      const commands = this.createCommands(o);
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        { name: OverviewComponent, commands }
      );
    });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;

    if (this.commandSubscription && !this.commandSubscription.closed)
      this.commandSubscription.unsubscribe();
    this.commandSubscription = undefined;

    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      OverviewComponent
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  protected createCommands(selected?: TimeReport): Command[] {
    return [
      {
        icon: 'fas fas fa-pen',
        name: '#TIME_REPORT.ACTIONS.EDIT',
        class: 'menu action-menu action-menu-top',
        canExecute: of(!!selected),
        routerLink: ['/arbeitsbericht', selected?.id, 'edit'],
      },
      new UploadFileCommand(this.store, {
        sources: 'all',
        canExecute: of(!!selected),
        archiveKey:
          selected?.archiveKey ??
          OverviewComponent.getConnectionResourceLocation(selected),
      }),
    ];
  }

  async onDateChange(date: string): Promise<void> {
    this.date = date;
    await this.router.navigate(['/arbeitsbericht', { date }], {
      replaceUrl: true,
    });
    this.canCreate$.next(await this.isDateEditable());
  }

  async showRecentObjectsDialog(): Promise<void> {
    const result: Objekt | 'DISMISSED' | undefined = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'RECENT_OBJECTS_DIALOG',
        component: RecentObjectsDialogComponent,
        dismiss: { button: true, escape: true, backdrop: false },
      }
    );

    if (!result || result === 'DISMISSED' || typeof result === 'string') return;

    this.selectedObject$.next(result);
  }

  async scanOrder(): Promise<void> {
    try {
      const scan = await this.store.dispatch(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.actions.scan,
        { labelType: 'code39' }
      );
      if(!scan.labelType)
        throw new Error('[OverviewComponent]scanOrder: scan labelType undefined')

      try {
        const object = await this.store.dispatch(
          SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
          TimeReportStore.actions.getObjectForOrder,
          {
            orderId: scan.data,
            labelType:
              typeof scan.labelType === 'string'
                ? scan.labelType
                : scan.labelType[0],
          }
        );
        this.selectedObject$.next(object);
      } catch (e) {
        handleRequestErrors(this.store, e)
      }
    } catch (e) {
      console.log(e)
    }
  }

  onPartyClick(): void {
    this.showPartyDialog();
  }

  onResetPersons(): void {
    this.selectedPersons = [];
    this.selectedObject$.next(null);
  }

  async showPartyDialog(): Promise<void> {
    const result = await this.store
      .dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open<Employee[], { selectedPersons: Employee[] }>(), {
        id: 'PART_DIALOG',
        component: PartyDialogComponent,
        data: { selectedPersons: this.selectedPersons },
        dismiss: { button: true, escape: true, backdrop: false },
      });
    if(result === 'DISMISSED')
      return;
    this.selectedPersons = [...(result ?? [])];
  }

  async onTimeReportSaved(): Promise<void> {
    const params = this.activeRoute.snapshot.paramMap;
    this.date = params.has('date')
      ? (params.get('date') ?? '')
      : this.dateService.today();
    this.date = this.date.substr(0, 10);

    const user: UserInfo = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    ) as UserInfo;

    if (!this.date || !user?.userId) return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.actions.loadReports,
        {
          from: this.date,
          to: this.date,
          userId: user?.userId + '',
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  removeChip(employee: Employee): void {
    this.selectedPersons = [
      ...this.selectedPersons.filter(({ id }) => id !== employee.id),
    ];
  }

  private subtractDaysToCurrentDate(days: number): Date {
    const subtractedDate = new Date();
    subtractedDate.setDate(subtractedDate.getDate() - days);
    return subtractedDate;
  }

  private async isDateEditable(): Promise<boolean> {
    if (
      this.timeReportConfig &&
      (this.timeReportConfig?.editableDaysBeforeToday ||
        this.timeReportConfig?.editableWeeksBeforeToday !== undefined)
    ) {
      if (this.timeReportConfig?.editableWeeksBeforeToday !== undefined) {
        const currentWeek = moment().format('w');
        const viewedWeek = moment(this.date).format('w');

        if (
          +currentWeek - this.timeReportConfig.editableWeeksBeforeToday >
          +viewedWeek
        ) {
          return false;
        }
      } else if (this.timeReportConfig?.editableDaysBeforeToday) {
        const lastEditableDate = this.subtractDaysToCurrentDate(
          this.timeReportConfig.editableDaysBeforeToday
        );
        if (
          new Date(this.date).getTime() / 1000 <
          lastEditableDate.getTime() / 1000
        ) {
          return false;
        }
      }
    }

    return true;
  }

  async openDetails(report: TimeReport | undefined): Promise<void> {
    if (!report) {
      this.selected$.next(undefined);
      return;
    }

    if (report?.editable !== undefined && report?.editable === false) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.info,
        {
          title: 'Dieser Eintrag darf nicht mehr geändert werden!',
        }
      );

      return;
    }

    if (await this.isDateEditable()) {
      this.selected$.value && this.selected$.value?.id === report?.id
        ? this.selected$.next(undefined)
        : this.selected$.next(report);

      if (
        !this.timeReportConfig ||
        this.timeReportConfig.clickAction === 'select' ||
        !this.timeReportConfig.clickAction
      ) {
        return;
      }

      await this.router.navigate(['/arbeitsbericht', report?.id, 'edit']);

      if (!this.timeReportConfig || !this.timeReportConfig.clickAction) {
        return;
      }

      await this.router.navigate(['arbeitsbericht', report?.id, 'edit']);
    } else {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.info,
        {
          title: 'Dieser Eintrag darf nicht mehr geändert werden!',
        }
      );
    }
  }
}
