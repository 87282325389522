<div class="soft-title light mt-3">
  {{ '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.TITLE' | translate }}
</div>
<div class="g auto-fill mt-2 mb-2 gap">
  <div class="tab" (click)="onSubmit(currentWeekQuery)">
    <a class="soft-link">
      {{ '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.CURRENT_WEEK' | translate }}
    </a>
  </div>
  <div class="tab" (click)="onSubmit(lastWeekQuery)">
    <a class="soft-link">
      {{ '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.LAST_WEEK' | translate }}
    </a>
  </div>
  <div class="tab" (click)="onSubmit(currentMonthQuery)">
    <a class="soft-link">
      {{
        '#TIME_REPORT.COMPONENTS.QUERY.QUICK_LINKS.CURRENT_MONTH' | translate
      }}
    </a>
  </div>
</div>

<div class="soft-title light mt-5">
  {{ '#TIME_REPORT.COMPONENTS.QUERY.MANUAL' | translate }}
</div>
<ng-container *ngIf="queryDefinition$ | async as definition">
  <div class="c column fill mt-2">
    <soft-dynamic-form [definition]="$any(definition)" #form class="fill"></soft-dynamic-form>

    <div class="c row end mt-3 mb-2">
      <span>
        <input
          [formGroup]="form.form"
          type="reset"
          class="soft-button link mr-4"
          (click)="form.form.reset()"
          value="{{ '#TIME_REPORT.COMPONENTS.QUERY.RESET' | translate }}"
        />
        <input
          [formGroup]="form.form"
          type="submit"
          class="soft-button primary"
          [disabled]="form.form.invalid"
          (click)="onSubmit(form.form.value)"
          value="{{ '#TIME_REPORT.COMPONENTS.QUERY.SUBMIT' | translate }}"
        />
      </span>
    </div>
  </div>
</ng-container>
