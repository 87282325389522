<div class="c row no-wrap center-v space-between pt-3 pb-3 rounded soft-card mt-3">
  <div class="c column no-wrap center-v truncate">
    <span class="text-xs overline-list overline-light-list mb-1 mr-2">{{item?.item?.number || item?.item?.id}}</span>
    <span class="truncate mb-1">{{item?.item?.name}}</span>
    <span class="text-sm color-light">{{ item?.amount | number:'1.2-2'}} {{ item?.item?.unit?.name || item?.item?.unit?.name }}</span>
  </div>
  <div class="trailing-content">
    <i class="fas fa-trash error-color font-larger ml-4 mr-1" (click)="delete.emit(item)"></i>
    <i class="fas fa-pen color-tertiary font-larger ml-4 mr-2" (click)="edit.emit(item)"></i>
  </div>
</div>
