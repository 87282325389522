import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore,} from '@softline/application';
import {DateService, Store} from '@softline/core';
import {SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY} from '../../arbeitsbericht.api';
import {TimeReportQuery} from '../../data/time-report.query';
import {Router} from '@angular/router';
import moment from 'moment';
import {DefinitionStore, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS,} from '@softline/dynamic';
import {UiCoreModule} from '@softline/ui-core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

interface Query {
  from: string;
  to: string;
  objectId?: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-query',
  standalone: true,
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule, DynamicModule],
})
export class QueryComponent implements OnInit, OnDestroy {
  currentWeekQuery: TimeReportQuery = {
    from: moment(this.dateService.today())
      .startOf('week')
      .toISOString(true)
      .substring(0, 10),
    to: this.dateService.today().substring(0, 10),
  };

  lastWeekQuery: TimeReportQuery = {
    from: moment(this.dateService.today())
      .add(-1, 'weeks')
      .startOf('week')
      .toISOString(true)
      .substring(0, 10),
    to: moment(this.dateService.today())
      .add(-1, 'weeks')
      .endOf('week')
      .toISOString(true)
      .substring(0, 10),
  };

  currentMonthQuery: TimeReportQuery = {
    from: moment(this.dateService.today())
      .startOf('month')
      .toISOString(true)
      .substring(0, 10),
    to: moment(this.dateService.today()).toISOString(true).substring(0, 10),
  };

  queryDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY
  );

  constructor(
    private store: Store,
    protected router: Router,
    private dateService: DateService,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIME_REPORT.COMPONENTS.QUERY.TITLE'
    );
    this.backNavigationService.set(this);
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/arbeitsbericht']);
  }

  onSubmit(query: any) {
    let { from, to, object } = query;
    from = from?.length > 0 ? from.substring(0, 10) : undefined;
    to = to?.length > 0 ? to.substring(0, 10) : undefined;
    const objectId = object ? object.id : undefined;
    const queryParams: Query = objectId ? { objectId, from, to } : { from, to };
    this.router.navigate(['/arbeitsbericht', 'result'], { queryParams });
  }
}
