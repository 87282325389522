<div class="soft-container soft-container-column mt-4">
  <strong>{{
    '#TIME_REPORT.COMPONENTS.QUERY_RESULT.FILTER' | translate
  }}</strong>
</div>
<soft-dynamic-filter
  [definition]="$any(queryDefinition$ | async)"
  [value]="$any(query$ | async)"
  (valueChange)="onQueryChange($event)"
>
</soft-dynamic-filter>

<ng-container
  *ngFor="
    let dailyReport of timeReports$ | async | groupBy: 'date';
    last as isLast
  "
>
  <h3 class="text-xl font-bold mt-4 mb-2">
    {{ dailyReport.key | format: 'DATE.DEFAULT' }}
  </h3>
  <soft-dynamic-list
    class="c"
    [definition]="$any(listDefinition$ | async)"
    [items]="dailyReport | toArray"
  >
  </soft-dynamic-list>
  <div class="c row space-between no-wrap mt-3">
    <strong class="ml-1">{{
      '#TIME_REPORT.COMPONENTS.LIST.TOTAL' | translate
    }}</strong>
    <span>{{ sum(dailyReport | toArray) | duration: 'hh:mm' }}</span>
  </div>

  <hr *ngIf="!isLast" class="mt-2 mt-4 w-100" />
</ng-container>

<ng-container *ngIf="loading$ | async">
  <div class="custom-loading-container">
    <soft-loading-spinner class="spin"></soft-loading-spinner>
  </div>
</ng-container>

<ng-container *ngIf="((timeReports$ | async)?.length ?? 0) < 1 && !(loading$ | async)">
  <div class="custom-loading-container">
    <span class="soft-text color-light">Es wurden keine Einträge erfunden</span>
  </div>
</ng-container>
