<div style="overflow-y: auto; padding-bottom: 4rem; margin-bottom: 1rem">
  <form [formGroup]="form" class="no-padding">
    <h1 class="soft-text-xl title-text">
      {{ '#DOCUMENTATION.TITLE' | translate }}
    </h1>

    <div class="soft-form-row">
      <div class="border-container">
        <div class="custom-button-container mt-2">
          <span
            class="custom-link-button bg-gray-200 rounded"
            (click)="scanOrder()"
          >
            {{ '#DOCUMENTATION.ACTION.SCAN_ORDER' | translate }}
            <i class="ml-2" [ngClass]="'fas fa-barcode'"></i>
          </span>
          <span
            class="custom-link-button bg-gray-200 rounded"
            (click)="showRecentObjectsDialog()"
          >
            {{ '#DOCUMENTATION.ACTION.RECENT_OBJECTS' | translate }}
            <i class="ml-2" [ngClass]="'fas fa-history'"></i>
          </span>
        </div>
        <div class="c row no-wrap mt-2">
          <div class="c column mt-2 mr-1">
            <label for="object" class="soft-label top required">{{
              '#DOCUMENTATION.CREATE.OBJECTNUMBER' | translate
            }}</label>
            <soft-value-converter formControlName="object"
                                  [converter]="objectValueConverter">
              <soft-field-ok
                name="objekt"
                [parameters]="{ status: 'O' }"
                #convertValue
                id="object"
              ></soft-field-ok>
            </soft-value-converter>
            <soft-validation-container
              *ngIf="form.controls['object'].touched"
              [validation]="form | validationMessage: 'object'"
              class="soft-property-validation"
            ></soft-validation-container>
          </div>
        </div>
        <div class="c row no-wrap mt-2">
          <div class="c column mt-2 mr-1">
            <label class="soft-label top required">{{
              '#DOCUMENTATION.CREATE.TITLE' | translate
            }}</label>
            <soft-input formControlName="title"></soft-input>
          </div>
        </div>
        <div class="c row no-wrap mt-2">
          <div class="c column mt-2 mr-1">
            <label class="soft-label top">{{
              '#DOCUMENTATION.CREATE.DESCRIPTION' | translate
            }}</label>
            <soft-multiline-input
              formControlName="description"
              [lines]="2"
            ></soft-multiline-input>
          </div>
        </div>
      </div>

      <div class="c row no-wrap mt-2">
        <div class="c column mt-2 mr-1">
          <soft-file-input
            #imageInput
            formControlName="files"
            [selectionMode]="'multi'"
          ></soft-file-input>
        </div>
      </div>
    </div>
  </form>
</div>

<soft-blocking-loading-spinner *ngIf="isSaving$ | async"></soft-blocking-loading-spinner>
