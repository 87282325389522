<ng-container *ngIf="(timeReports$ | async)?.length; else noDataTemplate">
  <soft-dynamic-list
    [definition]="$any(listDefinition$ | async)"
    [items]="(timeReports$ | async) ?? []"
    [selectionMode]="'single'"
    (selectedItemChange)="selectedChange.emit($event)"
  >
  </soft-dynamic-list>
  <hr class="mt-3 mb-2" />
  <div *ngIf="totalTime() as totalTime">
    <div class="c row space-between no-wrap center-v" [ngClass]="first ? 'mt-3' : 'mt-2'" *ngFor="let entry of totalTime; let first = first;">
      <strong class="ml-1">{{ (entry?.zbewe?.bezeichnung ?? '#TIME_REPORT.COMPONENTS.LIST.TOTAL') | translate }}</strong>
      <span>{{ entry?.duration | duration: 'hh:mm' }}</span>
    </div>
  </div>
</ng-container>
<ng-template #noDataTemplate>
  <div *ngIf="!(loading$ | async)" class="c row center mt-8">
    {{ '#TIME_REPORT.COMPONENTS.LIST.NO_DATA' | translate }}
  </div>
</ng-template>
