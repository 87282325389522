import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import {Store} from '@softline/core';
import {
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY,
} from '../arbeitsbericht.api';
import {SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT} from '../arbeitsbericht.shared';
import {TimeReportStore} from '../store/time-report.store';
import {DefinitionStore, SOFTLINE_FEATURE_DEFINITIONS,} from '@softline/dynamic';
import {Router, RouterModule} from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report',
  standalone: true,
  templateUrl: './time-report.component.html',
  styleUrls: ['./time-report.component.scss'],
  imports: [RouterModule],
})
export class TimeReportComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private zone: NgZone,
    protected router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIME_REPORT.TITLE'
    );
    const commands = this.createCommands();
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: TimeReportComponent,
        commands,
      }
    );

    this.store.commit(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.mutations.clear
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT }
    );
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      TimeReportComponent
    );
  }

  protected createCommands(): Command[] {
    return [
      {
        icon: 'fas fa-calendar-check',
        name: '#TIME_REPORT.MENU.LIST',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/arbeitsbericht'],
      },
      {
        icon: 'fas fa-search',
        name: '#TIME_REPORT.MENU.QUERY',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/arbeitsbericht', 'query'],
      },
    ];
  }
}
