import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { isDefined, Store } from '@softline/core';
import moment from 'moment';
import {
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY
} from '../../arbeitsbericht.api';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { TimeReport } from '../../data/time-report.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT } from '../../arbeitsbericht.shared';
import { TimeReportStore } from '../../store/time-report.store';
import {
  Definition,
  DefinitionStore,
  DynamicModule,
  ObjectDefinition,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import {
  AuthenticationContextStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
} from '@softline/auth';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { map } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-query-result',
  standalone: true,
  templateUrl: './query-result.component.html',
  styleUrls: ['./query-result.component.scss'],
  imports: [CommonModule, UiCoreModule, DynamicModule],
})
export class QueryResultComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;

  queryDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY
  );
  query$ = this.store.observe(
    SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
    TimeReportStore.getters.query.query
  );

  listDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST
  );

  timeReports$ = this.store.observe(
    SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
    TimeReportStore.getters.all
  );

  loading$ = this.store.observe(
    SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
    TimeReportStore.getters.loading
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#TIME_REPORT.COMPONENTS.QUERY_RESULT.TITLE'
    );
    const commands = this.createCommands();
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      { name: QueryResultComponent, commands }
    );
    this.subscription = combineLatest([
      this.activatedRoute.queryParamMap,
      this.store.observe(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.getters.data
      ),
    ]).subscribe(async ([params, user]: [any, any]) => {
      const from = params.get('from');
      const to = params.get('to');
      const objectId = +params.get('objectId');

      if (!user?.userId) {
        return;
      }

      this.store.commit(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.mutations.query.setQuery,
        objectId
          ? { from, to, objectId, userId: user?.userId }
          : { from, to, userId: user?.userId }
      );

      let loadParams = { from, to,  userId: user?.userId + '' }

      if (objectId && objectId !== 0) {
        loadParams['objectId'] = objectId
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.actions.loadReports,
        loadParams
      );
    });
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = undefined;
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/arbeitsbericht/query']);
  }

  createCommands(): Command[] {
    const query = this.store.get(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.getters.query.query
    );
    return [
      {
        icon: 'fas fa-list',
        name: '#TIME_REPORT.MENU.QUERY_RESULT',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/arbeitsbericht', 'result'],
        routerLinkParams: { queryParams: query },
      },
    ];
  }

  async onQueryChange(value: any): Promise<void> {
    const userId = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    )['userId'];
    this.store.commit(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.mutations.query.setQuery,
      { ...value, userId}
    );

    let loadParams = { from: value['from'].substring(0, 10), to: value['to'].substring(0, 10),  userId: userId } as any;

    if (isDefined(value['object'])) {
      loadParams['objectId'] = value['object']?.id
    }

    await this.store.dispatch(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.actions.loadReports,
      loadParams
    );
  }

  sum(reports: TimeReport[]): string {
    return reports
      .reduce((p, c) => p.add(c.duration), moment.duration(0))
      .toISOString();
  }
}
