import { Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  ConnectionResourceLocation,
} from '@softline/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Objekt } from '../data/objekt.model';
import {
  GET_OBJECT_BY_ID_RESOURCE_PATH,
  SCAN_OBJECT_RESOURCE_PATH,
} from '../arbeitsbericht.api';
import { LabelType } from '@softline/application';

@Injectable()
export class ScanOrderService {
  constructor(private service: ConnectionHttpService) {}

  private getObjectId(orderId: string): Observable<number> {
    const location: ConnectionResourceLocation = {
      path: SCAN_OBJECT_RESOURCE_PATH,
      pathParams: { type: 'code39', id: orderId },
    };
    return this.service.get<number>(location);
  }

  getObjectForOrder(
    orderId: string,
    type: LabelType | string
  ): Observable<Objekt> {
    const getLocation = (id: number): ConnectionResourceLocation => ({
      path: GET_OBJECT_BY_ID_RESOURCE_PATH,
      pathParams: { id },
    });
    return this.getObjectId(orderId).pipe(
      switchMap((objectId) => this.service.get<Objekt>(getLocation(objectId)))
    );
  }
}
