import { Injectable } from '@angular/core';
import { DateParser } from './date.parser';
import moment from 'moment';
import { MomentCalendarService } from './moment-calendar.service';
import { DateService } from '@softline/core';

@Injectable()
export class IsoDateParser<T> extends DateParser<string> {
  private format: string = 'DD.MM.YYYY HH:mm:ss';

  constructor(
    private dateService: DateService,
    private calendarService: MomentCalendarService
  ) {
    super();
    // @ts-ignore
    moment.suppressDeprecationWarnings = true;
    calendarService.format$.subscribe((format) => (this.format = format));
  }

  today(): moment.Moment {
    return moment(this.dateService.today());
  }

  parse(value: string): string | null {
    try {
      const date =
        this.parseTodayInput(value) ||
        this.parseMonthStartInput(value) ||
        this.parseMonthEndInput(value) ||
        this.parseTodayAndMonthInput(value) ||
        this.parseWeekInput(value) ||
        this.parseYearStartInput(value) ||
        this.parseYearEndInput(value) ||
        this.parseDayInput(value) ||
        this.parseDayAndMonthInput(value) ||
        this.parseDayMonthAndYearInput(value) ||
        this.parseLocaleInput(value) ||
        moment(value, false);
      return date?.toISOString(true) ?? null;
    } catch (message) {
      return null;
    }
  }

  private parseTodayInput(value: string): moment.Moment | null {
    if (value === 'h') return this.today();

    const match = value.match('^h([-|+]\\d+)$');
    if (match != null) return this.today().add(+match[1], 'days');

    return null;
  }

  private parseMonthStartInput(value: string): moment.Moment | null {
    if (value === 'ma') return this.today().startOf('months');

    const match1 = value.match('^ma(\\d+)$');
    if (match1 != null)
      return this.today()
        .startOf('year')
        .add(+match1[1] - 1, 'months');

    const match2 = value.match('^ma([-|+]\\d+)$');
    if (match2 != null)
      return this.today()
        .startOf('months')
        .add(+match2[1], 'months');

    return null;
  }

  private parseMonthEndInput(value: string): moment.Moment | null {
    if (value === 'me') return this.today().endOf('months');

    const match = value.match('^me([-|+]\\d+)$');
    if (match != null)
      return this.today()
        .endOf('months')
        .add(+match[1], 'months');

    return null;
  }

  private parseTodayAndMonthInput(value: string): moment.Moment | null {
    if (value === 'me') return this.today().endOf('months');

    const match = value.match('^h([-+]\\d+)[mM]$');
    if (match != null)
      return this.today().add(+match[1], 'months');

    return null;
  }

  private parseWeekInput(value: string): moment.Moment | null {
    const weekdays: string[] = this.calendarService.shortWeekdays.map((o) =>
      o.toLowerCase()
    );

    if (weekdays.indexOf(value) > -1)
      return this.today().startOf('week').add(weekdays.indexOf(value), 'days');

    const weekdaysAggregate = weekdays.join('|');
    const match1 = value.match(`^(${weekdaysAggregate})(\\d+)$`);
    if (match1 != null)
      return this.today()
        .startOf('year')
        .startOf('week')
        .add(weekdays.indexOf(match1[1]), 'days')
        .add(+match1[2] - 1, 'weeks');

    const match2 = value.match(`^(${weekdaysAggregate})([-|+]\\d+)$`);
    if (match2 != null)
      return this.today()
        .startOf('week')
        .add(weekdays.indexOf(match2[1]), 'days')
        .add(+match2[2], 'weeks');

    return null;
  }

  private parseYearStartInput(value: string): moment.Moment | null {
    if (value === 'ja') return this.today().startOf('year');

    const match1 = value.match('^ja(\\d+)$');
    if (match1 != null) return moment(`${this.getYear(+match1[1])}-01-01`);

    const match2 = value.match('^ja([-|+]\\d+)$');
    if (match2 != null)
      return this.today()
        .startOf('year')
        .add(+match2[1], 'years');

    return null;
  }

  private parseYearEndInput(value: string): moment.Moment | null {
    if (value === 'je') return this.today().endOf('year');

    const match1 = value.match('^je(\\d+)$');
    if (match1 != null) return moment(`${this.getYear(+match1[1])}-12-31`);

    const match2 = value.match('^je([-|+]\\d+)$');
    if (match2 != null)
      return this.today()
        .endOf('year')
        .add(+match2[1], 'years');

    return null;
  }

  private parseDayInput(value: string): moment.Moment | null {
    const match = /^\d{1,2}$/g.exec(value);
    if (match == null) return null;

    const day = +match[0];
    const date = this.today().startOf('month');
    if (day <= 0 || day > date.daysInMonth())
      throw Error(
        `Invalid day "${day}". Day has to be between 0 and ${date.daysInMonth()}}.`
      );

    return date.add(day - 1, 'days');
  }

  private parseDayAndMonthInput(value: string): moment.Moment | null {
    const match = /^(\d{2})(\d{1,2})$/g.exec(value);
    if (match == null) return null;

    let date = this.today().startOf('year');

    const day = +match[1];
    const month = +match[2];

    if (month <= 0 || month > 12)
      throw Error(
        `Invalid month "${month}". Month has to be between 1 and 12.`
      );

    date = date.add(month - 1, 'months');

    if (day <= 0 || day > date.daysInMonth())
      throw Error(
        `Invalid day "${day}". Day has to be between 0 and ${date.daysInMonth()}}.`
      );

    return date.add(day - 1, 'days');
  }

  private parseDayMonthAndYearInput(value: string): moment.Moment | null {
    const match = /^(\d{1,2})(\d{1,2})(\d{2,4})$/g.exec(value);
    if (match == null || match[3].length === 3) return null;

    const day = +match[1];
    const month = +match[2];
    const year = this.getYear(+match[3]);

    const date = moment(`${year}-${month}-${day}`);
    return date;
  }

  private parseLocaleInput(value: string): moment.Moment | null {
    const date = moment(value, this.format);
    if (!date.isValid())
      return null;
    return date;
  }

  private getYear(year: number) {
    if (year % 100 === year && year - 20 <= this.today().year() % 100)
      year = year + 2000;
    else if (year % 100 === year) year = year + 1900;
    return year;
  }
}
