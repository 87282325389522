import {
  createAction, createGetter,
  createMutation,
  Dictionary,
  mutate,
  on,
  QueryStore, select,
  SOFTLINE_STORE_ENTITY_SERVICE,
  StoreBuilder,
  StoreFeature,
  SyncedRemoteCollectionStore,
  TemplateStore,
} from '@softline/core';
import { TimeReport } from '../data/time-report.model';
import { TimeReportResourceService } from '../services/time-report.service';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { Objekt } from '../data/objekt.model';
import { ScanOrderService } from '../services/scan-order.service';
import { TimeReportQuery } from '../data/time-report.query';
import { SOFTLINE_PERMISSION_TIME_REPORT_PARTY } from '../arbeitsbericht.permissions';
import { lastValueFrom } from 'rxjs';

export interface State extends SyncedRemoteCollectionStore.State<TimeReport>, TemplateStore.State {
  sum: Partial<TimeReport>[]
}

class TimeReportStoreExtension {
  static actions = {
    loadReportDetails: createAction<State, any, { id: string }>(
      'loadReportDetails'
    ),
    loadReports: createAction<
      State,
      { from: string; to: string; userId: string; objectId?: number },
      TimeReport[]
    >('gewerbe/arbeitsbericht/loadReports'),
    createReport: createAction<
      State,
      { entity: TimeReport },
      TimeReport
    >('gewerbe/arbeitsbericht/createReport'),
    getObjectForOrder: createAction<
      State,
      { orderId: string; labelType: string },
      Objekt
    >('gewerbe/arbeitsbericht/getObjectForOrder'),
  };
  static getters = {
    sum: createGetter<State, Partial<TimeReport>[]>('gewerbe/arbeitsbericht/sum'),
  };
  static mutations = {
    updateArchiveKey: createMutation<
      State,
      { entity: TimeReport; archiveKey: string }
    >('gewerbe/arbeitsbericht/updateArchiveKey'),
    setSum: createMutation<
      State,
      Partial<TimeReport>[]
    >('gewerbe/arbeitsbericht/setSum'),
  };

  static feature: Partial<StoreFeature<State>> = {
    initialState: {
      sum: []
    } as unknown as State,
    mutations: [
      mutate(
        TimeReportStoreExtension.mutations.updateArchiveKey,
        ({ state, params }) => {
          const { entity, archiveKey } = params;
          const updatedEntity = { ...entity, archiveKey };
          const entityToUpdate = state.entities[entity.id];
          if (!entityToUpdate) {
            return state;
          }
          return {
            ...state,
            entities: { ...state.entities, [entity.id]: updatedEntity },
          };
        }
      ),
      mutate(
        TimeReportStoreExtension.mutations.setSum, ({ state, params }) => ({...state, sum: params})
      ),
    ],
    actions: [
      on(
        TimeReportStoreExtension.actions.loadReportDetails,
        async ({ params, injector, commit, featureName, get }) => {
          const service = injector.get(TimeReportResourceService);
          const isAuthorized = get(
            SOFTLINE_FEATURE_AUTHORIZATION,
            AuthorizationStore.getters.authorized,
            SOFTLINE_PERMISSION_TIME_REPORT_PARTY
          );
          const result = await lastValueFrom(
            service.getReportDetails(params.id, isAuthorized)
          );
          commit(featureName, TimeReportStore.mutations.addOrUpdate, result);
          return result;
        }
      ),
      on(
        TimeReportStoreExtension.actions.loadReports,
        async ({ featureName, commit, params, injector, get }) => {
          const service = injector.get(TimeReportResourceService);
          const isAuthorized = get(
            SOFTLINE_FEATURE_AUTHORIZATION,
            AuthorizationStore.getters.authorized,
            SOFTLINE_PERMISSION_TIME_REPORT_PARTY
          );
          const result = await lastValueFrom(
            service.getMany(
              !isAuthorized
                ? {
                    from: params.from,
                    to: params.to,
                    userId: params.userId,
                    objectId: params?.objectId,
                  }
                : {
                    from: params.from,
                    to: params.to,
                    userId: params.userId,
                    group: true,
                    objectId: params?.objectId,
                  }
            )
          );
          commit(featureName, TimeReportStore.mutations.clear);

          commit(
            featureName,
            TimeReportStore.mutations.addOrUpdateMany,
            result?.result ?? []
          );
          commit(
            featureName,
            TimeReportStore.mutations.setSum,
            result?.sum
          );

          return result?.result ?? [];
        }
      ),
      on(
        TimeReportStoreExtension.actions.createReport,
        async ({ featureName, commit, params, injector, get }) => {
          const service = injector.get(TimeReportResourceService);

          const isAuthorized = get(
            SOFTLINE_FEATURE_AUTHORIZATION,
            AuthorizationStore.getters.authorized,
            SOFTLINE_PERMISSION_TIME_REPORT_PARTY
          );

          const result = await lastValueFrom(
            service.create(
              params.entity,
              isAuthorized
            )
          );

          commit(featureName, TimeReportStore.mutations.clear);

          commit(
            featureName,
            TimeReportStore.mutations.addOrUpdate,
            result?.result
          );

          commit(
            featureName,
            TimeReportStore.mutations.setSum,
            result?.sum
          );

          return result?.result
        }
      ),
      on(
        TimeReportStoreExtension.actions.getObjectForOrder,
        async ({ params, injector }) => {
          const service = injector.get(ScanOrderService);
          return lastValueFrom(
            service.getObjectForOrder(params.orderId, params.labelType)
          );
        }
      ),
    ],
    getters: [
      select(TimeReportStoreExtension.getters.sum, ({state}) => state.sum)
    ]
  };
}
export const TimeReportStore = new StoreBuilder(
  SyncedRemoteCollectionStore.create<TimeReport>()
)
  .add(QueryStore.create<TimeReportQuery>())
  .add(TemplateStore.create<TimeReport>())
  .add(TimeReportStoreExtension).value;
