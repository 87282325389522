import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Modal, UiCoreModule } from '@softline/ui-core';
import { Employee } from '../../../data/employee.model';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FavoriteEmployee } from '../../../data/favorite-employee';
import {
  SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
  SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
} from '../../../arbeitsbericht.shared';
import { CoreDataStore } from '../../../store/core-data.store';
import { PartyFavoritesStore } from '../../../store/party-favorites.store';
import { handleRequestErrors } from '@softline/application';
import { CommonModule } from '@angular/common';
import { IsSelectedPipe } from '../../../pipes/is-selected.pipe';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-party-dialog',
  standalone: true,
  templateUrl: './party-dialog.component.html',
  styleUrls: ['./party-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, IsSelectedPipe],
})
export class PartyDialogComponent implements Modal<Employee[]>, OnInit {
  close!: (result: Employee[]) => void;

  readonly searchInput$ = new BehaviorSubject<string>('');

  selectedPersons: Employee[] = [];
  favoritePersons: number[] = [];

  readonly debouncedSearchInput$ = this.searchInput$.pipe(
    debounceTime(200),
    distinctUntilChanged()
  );

  readonly partyPersons$: Observable<(Employee & { favorite: boolean })[]> =
    combineLatest([
      this.store.observe(
        SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
        PartyFavoritesStore.getters.all
      ),
      this.store.observe(
        SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
        CoreDataStore.getters.selectPersonsWithFavoriteInfo
      ),
    ]).pipe(
      map(([favorites, allPersons]) =>
        (allPersons ?? [])
          .map((person) =>
            PartyDialogComponent.extendEmployeeWithFavoriteInfo(
              person,
              favorites
            )
          )
          .sort((a, b) =>
            a?.favorite && !b.favorite
              ? -1
              : !a?.favorite && b?.favorite
              ? 1
              : 0
          )
      )
    );

  readonly filteredPersons$: Observable<(Employee & { favorite?: any })[]> =
    combineLatest([this.partyPersons$, this.debouncedSearchInput$]).pipe(
      map(([persons, searchInput]) => {
        return searchInput.length < 1
          ? persons
          : persons.filter(
              ({ vorname, zuname }) =>
                (
                  vorname.toLowerCase().trim() +
                  ' ' +
                  zuname.toLowerCase().trim()
                ).indexOf(searchInput.toLowerCase().trim()) > -1
            );
      })
    );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
    CoreDataStore.getters.selectLoading
  );

  private static extendEmployeeWithFavoriteInfo(
    employee: Employee,
    favorites: readonly FavoriteEmployee[]
  ) {
    const isFavorite = (e: Employee) =>
      e.id === employee.id && e.persnr === employee.persnr;
    const favorite = favorites.find(({ lstfixBean }) => isFavorite(lstfixBean));
    return { ...employee, favorite: !!favorite, favoriteEntity: favorite };
  }

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
      CoreDataStore.actions.loadCoreData
    );
  }

  registerCloseHandler(handler: (result: Employee[]) => void) {
    this.close = handler;
  }

  selectOrUnselect({
    favorite,
    favoriteEntity,
    ...person
  }: Employee & { favorite?: FavoriteEmployee, favoriteEntity?: FavoriteEmployee }): void {
    const index = this.selectedPersons.findIndex((o) => o.id === person.id);

    if (index === -1) {
      this.selectedPersons = [...this.selectedPersons, person];
      return;
    }

    this.selectedPersons = [
      ...this.selectedPersons.slice(0, index),
      ...this.selectedPersons.slice(index + 1),
    ];
  }

  async favoriteChange(
    person: Employee & { favorite?: any; favoriteEntity?: FavoriteEmployee }
  ): Promise<void> {
    const { favorite, ...plainPerson } = person;

    try {
      if (!favorite)
        await this.store.dispatch(
          SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
          PartyFavoritesStore.actions.markAsFavorite,
          { employee: plainPerson }
        );
      else if(plainPerson?.favoriteEntity)
        await this.store.dispatch(
          SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
          PartyFavoritesStore.actions.delete,
          { entity: plainPerson?.favoriteEntity }
        );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  comparerFn(item: Employee, items: Employee[]): boolean {
    return items.findIndex(({ id }) => id === item.id) > -1;
  }

  favoriteComparerFn(id: number, favorites: number[]): boolean {
    return favorites.indexOf(id) > -1;
  }
}
