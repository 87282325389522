<h2 class="text-xl font-bold mt-3 mb-1">{{ '#SHOP.SEARCH.TITLE' | translate }}</h2>

<div class="c row space-between no-wrap center-v soft-input custom-input mt-3 mb-2">
  <input type="text" placeholder="{{ '#SHOP.SEARCH.INPUT_PLACEHOLDER' | translate }}" #input (input)="input$.next(input.value)">
  <i class="fas fa-search text-sm color-secondary ml-2 mr-2"></i>
</div>

<ng-container *ngIf="showMinInputPlaceholder$ | async; else content">
  <div class="c row center no-wrap min-height-40 mt-2 text-center color-light text-sm">
    {{ '#SHOP.SEARCH.MIN_INPUT_PLACEHOLDER' | translate }}
  </div>
</ng-container>

<ng-template #content>
  <lib-item-list
    displayType="search"
    [items]="(items$ | async) ?? []"
    [loading]="(loading$ | async) ?? false"
    [isInputEmpty]="input?.value === ''">
  </lib-item-list>
</ng-template>
