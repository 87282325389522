<ng-container *ngIf="inputDefinition$ | async as definition">
  <soft-dynamic-form
    #dynamicFormComponent
    [(value)]="value"
    (submit)="onSubmit(dynamicFormComponent.form.value)"
    [definition]="$any(definition)"
  >
  </soft-dynamic-form>

  <div
    class="c row center-v no-wrap mt-3"
    [ngClass]="(hasPartyPermission$ | async) ? 'space-between' : 'row-end'"
  >
    <div
      *ngIf="hasPartyPermission$ | async"
      style="height: 100%"
      class="c row center-v"
    >
      <a class="soft-link custom-link" (click)="partyClick.emit()">{{
        '#TIME_REPORT.COMPONENTS.INPUT.SELECT_PARTY' | translate
      }}</a>
    </div>

    <span>
      <button
        type="submit"
        class="soft-button primary flex flex-nowrap"
        [disabled]="(save$ | async) || (isFormValid$ | async) || !dynamicFormComponent.form.touched || (!dynamicFormComponent.formRef?.form?.valid || false)"
        (click)="onSubmit(dynamicFormComponent.form.value)"
      >
        <i *ngIf="(save$ | async)" class="fas fa-spinner fa-spin mr-1"></i>
        {{ '#TIME_REPORT.COMPONENTS.INPUT.SUBMIT' | translate }}
      </button>
    </span>
  </div>
</ng-container>
