<soft-date-navigator
  [date]="date"
  (dateChange)="onDateChange($event)"
  #navigator
>
  <div class="flex items-center flex-col">
    <div>{{ navigator.date | format: 'DATE.WITH_DAYNAME' }}</div>
    <div class="text-sm color-light">
      (KW {{ navigator.date | format: 'DATE.WEEK' }})
    </div>
  </div>
</soft-date-navigator>

<hr class="mt-4 mb-3" />

<div
  class="custom-padding mb-1"
  [ngClass]="
    timeReportConfig?.useSecondaryBorderStyle ? 'border-secondary' : 'border'
  "
>
  <div
    class="c row space-between center-v"
    (click)="isInputExpanded = !isInputExpanded"
  >
    <h5 class="text-base font-semibold">
      {{ '#TIME_REPORT.COMPONENTS.OVERVIEW.TITLE' | translate }}
    </h5>
    <i *ngIf="!isInputExpanded" class="fas fa-chevron-down h5"></i>
    <i *ngIf="isInputExpanded" class="fas fa-chevron-up h5"></i>
  </div>

  <ng-container *ngIf="isInputExpanded">
    <div
      class="custom-button-container mt-2"
      *ngIf="timeReportConfig && timeReportConfig?.actions"
    >
      <ng-container *ngFor="let action of timeReportConfig.actions">
        <span
          class="custom-link-button bg-gray-200 rounded"
          (click)="
            action.actionType === 'scanOrder'
              ? scanOrder()
              : showRecentObjectsDialog()
          "
        >
          {{ action.label }}
          <i class="ml-2" [ngClass]="action.icon"></i>
        </span>
      </ng-container>
    </div>

    <soft-time-report-input
      (partyClick)="onPartyClick()"
      (resetPersons)="onResetPersons()"
      (saveSuccess)="onTimeReportSaved()"
      [date]="navigator.date"
      [canCreate]="(canCreate$ | async) ?? false"
      [group]="selectedPersons"
      [recentObject]="selectedObject$ | async"
    >
    </soft-time-report-input>

    <div
      class="c row"
      *ngIf="(selectedPersons?.length ?? 0) > 0 && (hasPartyPermission$ | async)"
    >
      <soft-chip-group>
        <soft-chip
          (delete)="removeChip(selectedPerson)"
          [canDelete]="true"
          *ngFor="let selectedPerson of selectedPersons"
          class="mt-2 mr-2"
        >
          {{ selectedPerson?.vorname }} {{ selectedPerson?.zuname }}
        </soft-chip>
      </soft-chip-group>
    </div>
  </ng-container>
</div>

<soft-time-report-list
  [date]="navigator.date"
  (selectedChange)="openDetails($event)"
>
</soft-time-report-list>
