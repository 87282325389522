<div class="c row center-v bg-chip" *ngIf="breadcrumbs$ | async as breadcrumbs">
  <div class="pl-3 breadcrumb">
    <a [class.soft-link]="(breadcrumbs?.length || 0) > 0" [class.active]="(breadcrumbs?.length || 0) < 1" (click)="onSelect(null)">Hauptgruppen</a>
  </div>
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first">
    <span *ngIf="first" class="fas fa-chevron-right ml-2 mr-2" style="font-size: 0.75rem; opacity: 0.33"></span>

    <div class="breadcrumb">
      <a [class.soft-link]="!last" [class.active]="last" (click)="onSelect(breadcrumb)">{{ breadcrumb.title }}</a>
    </div>

    <span *ngIf="!last" class="fas fa-chevron-right ml-2 mr-2" style="font-size: 0.75rem; opacity: 0.33"></span>
  </ng-container>
</div>

<ng-content></ng-content>
