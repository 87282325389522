import {Component, EventEmitter, Input, OnInit, Output, Signal} from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST } from '../../../arbeitsbericht.api';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TimeReport } from '../../../data/time-report.model';
import { SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT } from '../../../arbeitsbericht.shared';
import { TimeReportStore } from '../../../store/time-report.store';
import {DynamicModule, ObjectDefinition, SOFTLINE_FEATURE_DEFINITIONS} from '@softline/dynamic';
import { DefinitionStore } from '@softline/dynamic';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [CommonModule, UiCoreModule, DynamicModule],
})
export class ListComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  private _date = new BehaviorSubject<string>(moment().toISOString(true));

  @Input()
  get date(): string {
    return this._date.value;
  }
  set date(value: string) {
    this._date.next(value);
  }

  @Output() selectedChange = new EventEmitter<TimeReport>();

  timeReports$ = combineLatest([
    this._date,
    this.store.observe(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.getters.all
    ),
  ]).pipe(
    map(([date, reports]) =>
      reports.filter((p) => moment(p.date).isSame(date, 'day'))
    ),
    map((reports) =>
      reports.sort((a, b) => {
        const aParts = a?.from?.split(':');
        const bParts = b?.from?.split(':');

        if (aParts && aParts?.length > 1 && bParts && bParts?.length > 1) {
          const aSeconds = +aParts[0] * 60 * 60 + +aParts[1] * 60 + +aParts[2];
          const bSeconds = +bParts[0] * 60 * 60 + +bParts[1] * 60 + +bParts[2];

          if (aSeconds > bSeconds) {
            return -1;
          } else if (aSeconds < bSeconds) {
            return 1;
          } else {
            return 0;
          }
        }

        return 0;
      })
    ),
    map((o) =>
      o.map((report) => ({
        ...report,
        from:
          report?.from?.split(':')?.length === 3
            ? report.from?.substring(0, report?.from.length - 3)
            : report.from,
        to:
          report?.to?.split(':')?.length === 3
            ? report.to?.substring(0, report?.to.length - 3)
            : report.to,
      }))
    ),
    map((o) =>
      o.map((report) =>
        report.customer
          ? report
          : { ...report, customer: { number: '', name: 'Kein Kunde' } }
      )
    )
  );

  loading$ = this.store.observe(
    SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
    TimeReportStore.getters.loading
  );

  readonly totalTime: Signal<Partial<TimeReport>[]> = this.store.signal(
    SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
    TimeReportStore.getters.sum
  );

  listDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST
  ).pipe(map(o => o as ObjectDefinition));

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
