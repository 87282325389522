import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {isDefinedNotEmpty, RequestError, Store} from "@softline/core";
import {
  DefinitionStore,
  DynamicFormComponent,
  DynamicModule,
  ObjectDefinition,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import {SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT} from '../../../arbeitsbericht.api';
import {TimeReport} from '../../../data/time-report.model';
import { BehaviorSubject, combineLatestWith, EMPTY, Observable, Subscription } from "rxjs";
import {Objekt} from '../../../data/objekt.model';
import {Employee} from '../../../data/employee.model';
import {map} from 'rxjs/operators';
import {AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION,} from '@softline/auth';
import {SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT} from '../../../arbeitsbericht.shared';
import {TimeReportStore} from '../../../store/time-report.store';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule,} from '@softline/ui-core';
import {handleRequestErrors} from '@softline/application';
import {CommonModule} from '@angular/common';
import {SOFTLINE_PERMISSION_TIME_REPORT_PARTY} from '../../../arbeitsbericht.permissions';
import moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-input',
  standalone: true,
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  imports: [CommonModule, UiCoreModule, DynamicModule],
})
export class InputComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription?: Subscription;

  save$ = new BehaviorSubject<boolean>(false);

  inputDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT
  ) as Observable<ObjectDefinition>;

  isFormValid$: Observable<boolean> = EMPTY;

  hasPartyPermission$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_TIME_REPORT_PARTY
  );

  value: Partial<TimeReport> = {};

  @Input() date: string = '';
  @Input() canCreate: boolean = false;
  @Input() group: Employee[] = [];
  @Input() timeReports$: Observable<TimeReport[]> = EMPTY;

  @Input()
  set recentObject(value: Objekt | null) {
    if (
      value &&
      this.dynamicForm &&
      this.dynamicForm?.form?.value?.object !== value
    ) {
      this.setObject(value);
    }
  }

  @Output() readonly partyClick = new EventEmitter();
  @Output() readonly resetPersons = new EventEmitter();
  @Output() readonly saveSuccess = new EventEmitter();

  @ViewChild('dynamicFormComponent', { static: false })
  private dynamicForm!: DynamicFormComponent<TimeReport>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.getters.template.template
      )
      .subscribe((template) => {
        this.value = template as TimeReport;
      });
  }

  setObject(object: { id: string; number: number; name: string }): void {
    if (!this.dynamicForm) {
      return;
    }
    this.dynamicForm?.form.patchValue({ object });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    this.subscription = undefined;
  }

  ngAfterViewInit(): void {
    if (!this.dynamicForm) {
      return;
    }
    this.dynamicForm.form.valueChanges.subscribe(
      (value) => {
        // TODO

        const date = this.timeToISODate(value.from)
        if (value?.from && this.date && date && date?.isValid()) {
          const allTimeReports = this.store.get(SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT, TimeReportStore.getters.all);
          const isBetweenAny = allTimeReports
            .filter(o => o.date.includes(this.date))
            .some(o => date.isBetween(this.timeToISODate(o.from)!, this.timeToISODate(o.to)!))

          if (isBetweenAny) {
            this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.info, {
              title: 'Überschneidung',
              message: 'Es gibt bereits einen Zeiteintrag der sich überschneidet!'
            })
          }
        }
      }
    )
    this.isFormValid$ = this.dynamicForm.form.valueChanges.pipe(
      combineLatestWith(this.inputDefinition$),
      map(([o, definition]) => this.requiredFieldsFilled(o, definition))
    );
  }

  requiredFieldsFilled(value: {
    description: string;
    object?: { id: number } | null;
    from: string | null;
    to: string | null;
    zbewegungsarten?: any | null;
  }, definition: ObjectDefinition): boolean {
    const bewegunsartDef = definition?.definitions.find(o => (o as any)?.name === 'zbewegungsarten');

    if (bewegunsartDef && (bewegunsartDef as any)?.required) {
      return !value?.object || !value?.from || !value?.to || !value?.zbewegungsarten;
    }

    return !value?.object || !value?.from || !value?.to;
  }

  private timeToISODate(time: string): moment.Moment | null {
    if ((time?.substring(1)?.split(':')?.length ?? 0) < 3) {
      return null
    }

    const [hours, minutes, seconds] = time.substring(1).split(':')
    return moment(this.date).set({ hours: +hours, minutes: +minutes, seconds: +seconds})
  }

  async onSubmit(entity: TimeReport): Promise<void> {
    if (!this.canCreate) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: 'Es darf keine Arbeitszeit mehr erfasst werden!',
        }
      );
      return;
    }

    if (!entity?.from || !entity?.to) return;

    entity = { ...entity };
    entity.date = this.date;
    entity.group = this.group;

    if (
      this.dynamicForm?.definition?.definitions?.find(
        (o) => o.type === 'fieldOk' && o.name === 'zbewegungsarten'
      )
    ) {
      (entity as any).zbewegungsarten = [entity?.zbewegungsarten];
    }

    if (entity.from?.startsWith('T')) {
      entity.from = entity.from.substring(1, 9);
    }
    if (entity.to?.startsWith('T')) {
      entity.to = entity.to.substring(1, 9);
    }
    if (entity?.from2 && entity?.from2?.startsWith('T')) {
      entity.from2 = entity?.from2?.substring(1, 9);
    }
    if (entity?.to2 && entity?.to2?.startsWith('T')) {
      entity.to2 = entity?.to2?.substring(1, 9);
    }

    if (!isDefinedNotEmpty(entity?.from2)) {
      entity.from2 = undefined;
    }
    if (!isDefinedNotEmpty(entity?.to2)) {
      entity.to2 = undefined;
    }

    if (!this.isTimespanValid(entity?.from, entity?.to)) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: 'Ungültiger Zeitraum',
          message: 'Die Von-Zeit muss kleiner sein als die Bis-Zeit',
        }
      );
      return;
    }

    if (
      entity?.from2 &&
      entity?.to2 &&
      !this.isTimespanValid(entity?.from2, entity?.to2)
    ) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          title: 'Ungültiger Zeitraum',
          message: 'Die Von-Zeit muss kleiner sein als die Bis-Zeit',
        }
      );
      return;
    }

    try {
      this.save$.next(true);
      await this.store.dispatch(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.actions.createReport,
        { entity }
      );
      this.saveSuccess.emit(); // Emit the save event so that we can reload the data (call /entities again)

      this.save$.next(false);
      this.value = this.store.get(
        SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
        TimeReportStore.getters.template.template
      ) as TimeReport;

      if (this.dynamicForm) {
        this.resetPersons.emit();
        this.dynamicForm.form.reset();
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#TIME_REPORT.MESSAGES.SUCCESS.TITLE',
          message: '#TIME_REPORT.MESSAGES.SUCCESS.SAVE',
        }
      );
    } catch (e) {
      this.save$.next(false);
      handleRequestErrors(this.store, e);
    }
    finally {
      this.save$.next(false);
    }
  }

  private isTimespanValid(from: string, to: string): boolean {
    const fromParts = from?.split(':');
    const toParts = to?.split(':');

    if (fromParts && fromParts?.length > 1 && toParts && toParts?.length > 1) {
      const fromSeconds =
        +fromParts[0] * 60 * 60 + +fromParts[1] * 60 + +fromParts[2];
      const toSeconds = +toParts[0] * 60 * 60 + +toParts[1] * 60 + +toParts[2];
      return fromSeconds < toSeconds;
    }

    return false;
  }
}
