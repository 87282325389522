<h2 class="text-xl font-bold mt-3 mb-1">{{ '#SHOP.CART.TITLE' | translate }}</h2>

<div class="info-section" *ngIf="orderLocation$ | async as location; else selectOrderWarning">
  {{ '#SHOP.CART.DESTINATION_INFO' | translate }} {{ location?.vkforg }}
</div>

<ng-template #selectOrderWarning>
  <div class="warning-section">
    {{ '#SHOP.CART.DESTINATION_EMPTY' | translate }}
  </div>
</ng-template>

<div class="c no-wrap construction-site mt-2 column center-v">
    <div class="c row column no-wrap">
      <h4 class="text-bold">{{ '#SHOP.CART.ORDER_DETAILS' | translate }}</h4>
      <div class="custom-button-container mt-2">
        <div class="custom-link-button !bg-gray-200 rounded mt-2" (click)="scanObject()">
          {{ '#SHOP.CART.SCAN_OBJECT' | translate }}
          <i class="fas fa-barcode ml-2"></i>
        </div>
        <div class="custom-link-button !bg-gray-200 rounded mt-2" (click)="showAddressDialog()">
          {{ '#SHOP.CART.CHANGE_OBJECT' | translate }}
          <i class="fas fa-history ml-2"></i>
        </div>
      </div>
    </div>
    <soft-value-converter [converter]="objectConverter"
                          [value]="$any(object$ | async)"
                          (valueChange)="selectObject($event)">
      <soft-field-ok
        #fok
        #convertValue
        class="mt-3"
        [name]="'objekt'"
        [parameters]="{ status: 'O' }"
        [placeholder]="'#SHOP.CART.OBJECT_PLACEHOLDER' | translate">
      </soft-field-ok>
    </soft-value-converter>
</div>

<section class="soft-section mt-5 padding-button" *ngIf="cartItems$ | async as cartItems">
  <ng-container *ngIf="loading$ | async; else content">
    <div class="c row center mt-1 no-wrap min-height-40">
      <soft-loading-spinner class="spin"></soft-loading-spinner>
    </div>
  </ng-container>

  <ng-template #content>
    <ng-container *ngIf="(cartItems?.length ?? 0) > 0; else placeholder">
      <h6 class="soft-title color-tertiary mb-1">{{ '#SHOP.CART.ITEMS_HEADER' | translate }}</h6>

      <lib-cart-item
        *ngFor="let item of cartItems; let last = last; trackBy: trackByFn"
        [item]="item"
        [last]="last"
        (edit)="onEdit($event)"
        (delete)="onDelete($event)">
      </lib-cart-item>
    </ng-container>

    <ng-template #placeholder>
      <div class="c row center mt-4 no-wrap min-height-40">
        <span class="text-s color-light">{{ '#SHOP.CART.NO_ITEMS' | translate }}</span>
      </div>
    </ng-template>
  </ng-template>
</section>

<button class="fixed-button soft-button primary" [class.disabled-order-button]="!fok?.value" (click)="order(fok?.value)" [disabled]="(isProcessing$ | async) || !(orderLocation$ | async) || !fok?.value">
  <ng-container *ngIf="!(isProcessing$ | async); else loadingSpinner">{{ '#SHOP.CART.ORDER_BUTTON' | translate }}</ng-container>
  <ng-template #loadingSpinner>
    <soft-loading-spinner class="spin"></soft-loading-spinner>
  </ng-template>
</button>

