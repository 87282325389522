<ng-container *ngIf="timeReport$ | async as timeReport">
  <soft-dynamic-form
    class="block mt-4"
    #dynamicForm
    [value]="timeReport"
    [definition]="$any(inputDefinition$ | async)"
  >
  </soft-dynamic-form>
  <section class="mt-5 mb-3">
    <h6 class="soft-overline colored condensed">
      {{ '#TIME_REPORT.FILES.HEADER' | translate }}
    </h6>
    <soft-archive-list
      [archiveKey]="timeReport?.archiveKey || $any(timeReport)?.archivekey"
    ></soft-archive-list>
  </section>

  <section
    *ngIf="
      timeReport?.group &&
      (timeReport?.group?.length ?? 0) > 0 &&
      (isAuthorized$ | async)
    "
  >
    <h6 class="soft-overline colored condensed mb-1 mt-4">
      {{ '#TIME_REPORT.COMPONENTS.EDIT.RECORDED_FOR' | translate }}
    </h6>
    <soft-chip-group>
      <soft-chip
        *ngFor="let person of timeReport?.group"
        style="margin-top: 0.25rem; margin-right: 0.25rem"
        [canDelete]="false"
      >
        {{ person?.vorname }} {{ person?.zuname }} ({{ person.persnr }})
      </soft-chip>
    </soft-chip-group>
  </section>
  <div class="c row end mt-4">
    <button
      class="soft-button primary flex flex-row flex-nowrap items-center"
      [disabled]="(saving$ | async) || dynamicForm?.form?.invalid"
      (click)="onSubmit(dynamicForm.form.value)"
    >
      <i *ngIf="saving$ | async" class="fas fa-spinner fa-spin mr-1"></i>
      {{ '#TIME_REPORT.COMPONENTS.EDIT.SUBMIT' | translate }}
    </button>
  </div>
  <hr class="m-4" />
  <soft-dynamic-object *ngIf="!timeReportConfig?.hideEntryInDetails || timeReportConfig?.hideEntryInDetails === false"
    class="mb-4"
    [value]="timeReport"
    [definition]="$any(listDefinition$ | async)"
  >
  </soft-dynamic-object>

  <ng-container *ngIf="timeReport?.object && timeReport?.object?.geodaten">
    <div class="flex items-center justify-between mb-2 mt-6">
      <div class="text-xl font-semibold">
        {{ '#TIME_REPORT.COMPONENTS.EDIT.GEODATEN' | translate }}
      </div>
      <a
        target="_blank"
        class="soft-link"
        [attr.href]="getGoogleLink(timeReport?.object)"
        >{{ '#TIME_REPORT.COMPONENTS.EDIT.GEO_MAPS_LINK' | translate }}</a
      >
    </div>
    <section class="soft-container">
      <div
        class="soft-card soft-card-rounded w-full border-bottom divide-y divide-gray-200"
        style="padding: 0"
      >
        <div class="soft-container space-between p-4">
          <div class="soft-container-column-start text-sm">
            <strong>{{
              '#TIME_REPORT.COMPONENTS.EDIT.GPS_LAENGE' | translate
            }}</strong>
          </div>
          <div class="soft-contianer--column-end text-sm">
            {{ timeReport?.object?.geodaten?.gpslaenge || '-' }}
          </div>
        </div>
        <div class="soft-container space-between p-4">
          <div class="soft-container-column-start text-sm">
            <strong>{{
              '#TIME_REPORT.COMPONENTS.EDIT.GPS_BREITE' | translate
            }}</strong>
          </div>
          <div class="soft-contianer--column-end text-sm">
            {{ timeReport?.object?.geodaten?.gpsbreite || '-' }}
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>
