import { ApplicationConfig } from '@angular/core';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { ConnectionModule, CoreModule, StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  connectionSettingsProviders,
  contextStorageProviders,
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
  vkforgContextProviders,
} from '@softapps/allgemein/utils';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule,
  MasterDataModule,
  OfflineModule,
} from '@softline/application';
import { DynamicModule, SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import { passwordProviders } from '@softapps/allgemein/password';
import { loginProviders } from '@softapps/allgemein/login';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { moduleProviders } from '@softapps/allgemein/modules';
import {
  arbeitsberichtProviders,
  SOFTLINE_CONFIG_ARBEITSBERICHT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY,
} from '@softapps/gewerbe/arbeitsbericht';
import { dokumentationProviders } from '@softapps/gewerbe/dokumentation';
import { objektabfrageProviders } from '@softapps/wws/objektabfrage';
import { bestellungProviders } from '@softapps/gewerbe/bestellung';
import { platform } from '../platforms/platform';

import { default as queryReisingerDefinition } from '../definitions/query.json';
import { default as listReisingerDefinition } from '../definitions/list.json';
import { default as inputReisingerDefinition } from '../definitions/input.json';
import { default as editReisingerDefinition } from '../definitions/edit.json';
import { wwsCoreProviders } from "@softapps/wws/core";
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      OfflineModule.forRoot()
    ),

    //Allgemein
    ...utilitiesProviders,
    ...settingsProviders,
    ...connectionSettingsProviders,
    ...passwordProviders,
    ...loginProviders,
    ...pdfViewerProviders,
    ...moduleProviders,

    // Context
    ...userContextProviders,
    ...vkforgContextProviders,
    ...contextStorageProviders,

    //WWS
    ...wwsCoreProviders,

    //Gewerbe
    ...arbeitsberichtProviders,
    ...dokumentationProviders,
    ...objektabfrageProviders,
    ...bestellungProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: SOFTLINE_CONFIG_ARBEITSBERICHT,
      useValue: {
        clickAction: 'navigate',
        useSecondaryBorderStyle: true,
        editableWeeksBeforeToday: 0,
        actions: [
          {
            label: 'Auftrag Scannen',
            icon: 'fas fa-barcode',
            actionType: 'scanOrder',
          },
          {
            label: 'Letzte Objekte',
            icon: 'fas fa-history',
            actionType: 'recentObjects',
          },
        ],
      },
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
        definition: listReisingerDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
        definition: inputReisingerDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
        definition: editReisingerDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY,
        definition: queryReisingerDefinition,
        priority: 1,
      },
      multi: true,
    },
  ],
};
