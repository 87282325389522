<h2 class="text-xl font-bold mt-3 mb-1">{{ '#SHOP.ITEM_GROUPS.TITLE' | translate }}</h2>

<div class="mt-3 mb-3">
  <a class="favorites-link c row center-v space-between no-wrap" (click)="navigateToFavorites()">
    Meine Favoriten anzeigen
    <i class="fas fa-chevron-right text-sm color-light"></i>
  </a>
</div>

<lib-item-navigation-container>
  <div class="pb-2" *ngIf="visibleAttributes$ | async as attributes">
    <ng-container *ngIf="loading$ | async; else content">
      <div class="c row center no-wrap pt-4 pb-4 mt-1 min-height-50">
        <soft-loading-spinner class="spin"></soft-loading-spinner>
      </div>
    </ng-container>

    <ng-template #content>
      <lib-list-item
        *ngFor="let attribute of attributes; let last = last; trackBy: trackByFn"
        [item]="attribute"
        [last]="last"
        (selected)="onSelect($any($event))">
      </lib-list-item>
    </ng-template>
  </div>
</lib-item-navigation-container>
