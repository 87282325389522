<div class="c row center-v space-between no-wrap pt-2 pb-2" (click)="onSelect(item)" [class.separator]="!last">

  <ng-container *ngIf="type === 'attribute' && isAttribute(item) && item as attribute">
    <div class="c column w-100 truncate">
      <div class="text-xs overline-list overline-light-list mb-1 mr-2">{{ attribute.name }}</div>
      <span class="truncate font-medium">{{ attribute.title }}</span>
    </div>
    <div class="trailing-content flex-row">
      <div class="count-badge">
        {{ attribute.itemCount }}
      </div>
      <i class="fas fa-chevron-righttext-xs color-lighter"></i>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'item' && isItem(item)">
    <div class="c column w-100 truncate">
      <div class="text-xs overline-list overline-light-list mb-1 mr-2">{{ item?.number }}</div>
      <span class="truncate">{{ item?.name }}</span>
    </div>
    <div class="trailing-content flex-row">
      <ng-container *ngIf="!favoritesUpdating; else loadingIcon">
        <i
          class="fa-heart icon-larger color-primary ml-3 mr-2"
          [ngClass]="isFavorite ? 'fas' : 'far'"
          (click)="favoritesTapped(item, isFavorite ? 'remove' : 'add')">
        </i>
      </ng-container>
      <ng-template #loadingIcon>
        <soft-loading-spinner class="small spin mr-2 pl-2 pr-1"></soft-loading-spinner>
      </ng-template>
      <div class="pos-rel">
        <i class="fas fa-shopping-bag icon-larger ml-2 color-tertiary" (click)="addToCartTapped(item)"></i>
      </div>
      <ng-container *ngIf="cartAmount && cartAmount > 0">
        <span class="card-badge" (click)="addToCartTapped(item)">{{ cartAmount | number:'1.0-0' }}</span>
      </ng-container>
    </div>
  </ng-container>
</div>
